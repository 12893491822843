/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    issues: [],
    currentIssue: {},
    filters: {
      search: "",
      status: null,
      condominium: null,
      types: null
    },
    statusOptions: [
      "new",
      "incomplete",
      "assigned-maintainer",
      "assigned-administrator",
      "maintainer-closed",
      "closed",
      "suspended",
      "deleted"
    ],
    sortBy: "createdAt",
    sortDesc: true,
    availableMaintainers: []
  };
};
const state = getDefaultState();

const getters = {
  getIssues: state => state.issues,
  getCurrentIssue: state => state.currentIssue,

  getStatusOptions: state => state.statusOptions,

  getFilters: state => state.filters,
  getFilterSearch: state => state.filters.search,
  getFilterStatus: state => state.filters.status,
  getFilterCondominium: state => state.filters.condominium,
  getFilterReported: state => state.filters.reported,
  getFilterTypes: state => state.filters.types,

  getSortBy: state => state.sortBy,
  getSortDesc: state => state.sortDesc
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_ISSUES(state, issues) {
    state.issues = issues;
  },
  SET_CURRENT_ISSUE(state, issue) {
    state.currentIssue = issue;
  },

  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
  SET_FILTER_SEARCH(state, search) {
    state.filters.search = search;
  },
  SET_FILTER_STATUS(state, status) {
    state.filters.status = status;
  },
  SET_FILTER_CONDOMINIUM(state, condominium) {
    state.filters.condominium = condominium;
  },
  SET_FILTER_REPORTED(state, reported) {
    state.filters.reported = reported;
  },
  SET_FILTER_TYPES(state, values) {
    state.filters.types = values;
  },

  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_DESC(state, sortDesc) {
    state.sortDesc = sortDesc;
  }
};

const actions = {
  async retrieveUserIssues({ commit, getters, rootGetters }) {
    try {
      const parameters = {
        search: getters.getFilterSearch,
        status: getters.getFilterStatus
      };
      if (getters.getFilterCondominium) {
        parameters.condominiumId = getters.getFilterCondominium;
      }
      if (getters.getFilterReported) {
        parameters.isReported = getters.getFilterReported;
      }

      if (getters.getFilterTypes) {
        parameters.type = getters.getFilterTypes.join(",");
      }

      const response = await services.MaintainerIssueService.retrieveUserIssues(
        parameters
      );
      commit("SET_ISSUES", response);
    } catch (error) {
      let messageText = null;
      if (
        error.response?.body?.code === 404 &&
        error.response?.body?.errorCode === 763
      )
        messageText = "page.issue.noCondominiumErrorMsg";
      console.log(error.response?.body || error);
      SET_ERROR(error, { messageText });
      return Promise.reject();
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
